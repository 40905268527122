import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { getAuth } from 'firebase/auth';

Vue.use(VueRouter);

const routes = [
  // Admin Routes
  {
    path: '/admin',
    name: 'admin-marathon',
    component: () => import('@/views/admin/AdminSettings.vue'),
    meta: {
      admin: true,
      layout: 'content',
      protected: true,
    },
  },
  {
    path: '/',
    redirect: 'home',
    meta: {
      protected: true,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
      protected: true,
      gtm: 'Home',
    },
  },
  {
    path: '/marathon',
    name: 'marathon',
    component: () => import('@/views/MarathonsHome.vue'),
    meta: {
      layout: 'content',
      protected: true,
      gtm: 'My Marathons',
    },
  },
  {
    path: '/marathon/:marathon_id',
    name: 'marathonSingle',
    component: () => import('@/views/marathon/MarathonSingle.vue'),
    meta: {
      layout: 'content',
      protected: true,
    },
  },
  {
    path: '/marathon/:marathon_id/day/:day_id',
    name: 'daySingle',
    props: true,
    component: () => import('@/views/marathon/DaySingle.vue'),
    meta: {
      layout: 'content',
      protected: true,
    },
  },
  {
    path: '/goals',
    name: 'goals',
    component: () => import('@/views/Goals.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
      gtm: 'Login',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      gtm: 'Register',
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

function isInWebView() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (
    userAgent.indexOf('Instagram') > -1 ||
    userAgent.indexOf('FBAN') > -1 ||
    userAgent.indexOf('FBAV') > -1 ||
    userAgent.indexOf('Viber') > -1
  );
}

router.beforeEach(async (to, from, next) => {
  const auth = getAuth();
  const user = auth.currentUser;

  // Check if we are in a webview and haven't redirected yet
  const isRedirected = localStorage.getItem('isRedirected');

  if (isInWebView() && !isRedirected) {
    // Set the flag to prevent infinite redirects
    localStorage.setItem('isRedirected', 'true');

    // Preserve the current URL
    const currentUrl = window.location.href;

    // Open the app in the default browser, with the current URL as a query parameter
    window.open(
      `https://app.tanyagundi.com/?redirect=${encodeURIComponent(currentUrl)}`,
      '_blank'
    );

    // Stop here to prevent any further actions in the webview
    return;
  } else if (!isInWebView()) {
    // Clear the flag once the user is in a normal browser
    localStorage.removeItem('isRedirected');

    // Check for the 'redirect' query parameter
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirect');

    // If a redirect URL is provided, navigate there
    if (redirectUrl) {
      window.location.href = decodeURIComponent(redirectUrl);
      return; // Prevent further navigation in this case
    }
  }

  // Your existing auth logic starts here
  if (to.matched.some((record) => record.meta.protected)) {
    // If the route requires admin privileges
    if (to.matched.some((record) => record.meta.admin)) {
      if (user && user.email === 'danielgundi@gmail.com') {
        next();
      } else {
        next({ name: 'home' });
      }
    } else {
      // If the route is protected but doesn't require admin access
      if (user) {
        next();
      } else {
        // Store the redirect URI if user is not authenticated
        if (to.query) {
          const params = new URLSearchParams(to.query);
          localStorage.redirecUri = `${to.path}?${params.toString()}`;
        } else {
          localStorage.redirecUri = to.path;
        }
        next({ name: 'auth-register' });
      }
    }
  } else if (
    to.matched.some((record) => record.meta.redirectIfLoggedIn) &&
    user
  ) {
    // Handle redirect if user is already logged in
    if (localStorage.redirecUri) {
      next(localStorage.redirecUri);
      localStorage.removeItem('redirecUri');
    } else {
      next({ name: 'home' });
    }
  } else {
    next();
  }
});

export default router;
